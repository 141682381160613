<template>
  <div class="sitemap">
    <div class="note">
      NOTE: Hit SHIFT + S to open up Scene Settings.
    </div>
    <h1>Website Sitemap</h1>
    <ul>
      <li><a href="/login">Login View</a><div class="props">Props: None required.</div></li>
      <li><a href="/">Landing</a><div class="props">Props: feature_footer (optional)</div></li>
      <li><a href="/thanks">Thanks For Watching</a><div class="props">Props: feature_footer (optional)</div></li>
      <li><a href="/bg">Background View</a><div class="props">Props: feature_footer (optional)</div></li>
      <li><a href="/brb">Be Right Back</a><div class="props">Props: feature_footer (optional)</div></li>
      <li><a href="/about">About</a><div class="props">Props: feature_footer (optional)</div></li>
      <li><a href="/scoreboard/overwatch/:matchID">OverWatch</a><div class="props">Props: matchID, feature_footer (optional)</div></li>
      <li><a href="/MatchUp/:matchID">MatchUp</a><div class="props">Props: matchID, team_a_c, team_b_c, team_a_name, team_b_name, team_a_score, team_b_score, is_gradient, feature_footer (all optional)</div></li>
      <li><a href="/MatchUp/default/1/:matchID">DefaultMatchup1</a><div class="props">Props: matchID, team_a_c, team_b_c, team_a_name, team_b_name, team_a_score, team_b_score, is_gradient, feature_footer (all optional)</div></li>
      <li><a href="/MatchUp/default/2/:matchID">DefaultMatchup2</a><div class="props">Props: matchID, team_a_c, team_b_c, team_a_name, team_b_name, team_a_score, team_b_score, is_gradient, feature_footer (all optional)</div></li>
      <li><a href="/groupstanding">Group Standing</a><div class="props">Props: feature_footer (optional)</div></li>
      <li><a href="/scoreboard/default/:matchID">ScoreBoard Default</a><div class="props">Props: matchID, team_a_c, team_b_c, team_a_name, team_b_name, team_a_score, team_b_score, feature_footer (all optional)</div></li>
      <li><a href="/scoreboard/rocketleague/:matchID">Rocket League</a><div class="props">Props: matchID, team_a_c, team_b_c, team_a_name, team_b_name, team_a_score, team_b_score, feature_footer (all optional)</div></li>
      <li><a href="/tournamentMatches/:NoCells">Tournament Matches</a><div class="props">Props: NoCells</div></li>
      <li><a href="/startingsoon/:matchID">Starting Soon</a><div class="props">Props: matchID</div></li>
      <li><a href="/footer">App Footer</a><div class="props">Props: footer_background, text_color (all optional)</div></li>
    </ul>
  </div>

</template>
<script>
// @ is an alias to /src
export default {
  name: 'ThanksForWatching',
  mounted(){
    const gradientString = this.$route.query.feature_footer;
    const GradientBool = gradientString === 'true' ? true : false;
    this.$store.dispatch("feature_footer", GradientBool);
  }
}
</script>
<style>
.sitemap ul {
  list-style-type: none;
}
.sitemap li {
  margin-bottom: 10px;
}
.sitemap a {
  text-decoration: none;
  color: blue;
}
.props {
  font-size: 0.9em;
  color: grey;
  margin-left: 20px;
}
.note {
  color: red;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
}
</style>