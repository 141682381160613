import Vue from 'vue'
import VueRouter from 'vue-router'
import BeRightBack from '../views/BeRightBackView.vue'
import ThanksForWatchingView from '../views/ThanksForWatching.vue'
import LandingView from '../views/LandingView.vue'
import AboutView from '../views/AboutView.vue'
import BackgroundView from '../views/BackgroundView.vue'
import MatchUp from '../views/MatchUp.vue'
import GroupStanding from '../views/GroupStanding.vue'
import tournamentMatches from '../views/tournamentMatches.vue'
import ScoreBoardDefault from '../views/ScoreBoardDefault.vue'
import RocketLeague from '../views/RocketLeague.vue'
import OverWatch from '../views/OverWatch.vue'
import DefaultMatchup1 from '../views/DefaultMatchup1.vue'
import DefaultMatchup2 from '../views/DefaultMatchup2.vue'
import LoginView from '../views/LoginView.vue'
import StartingSoon from '../views/StartingSoon.vue'
import AppFooter from '../views/AppFooter.vue'
import WelcomePage from '../views/WelcomePage.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    meta: { transition: 'slide-in' },
  },
    {
      path: '/welcome/:tournamentID',
      name: 'WelcomePage',
      component: WelcomePage,
      meta: { transition: 'slide-in' },
      props: route => ({
        HeadlineText: route.query.HeadlineText || 'Welcome to the Ugc gamings',
      }),
    },
  {
    path: '/',
    name: 'landing',
    component: LandingView,
    meta: { transition: 'slide-in' },
    props: route => ({
      feature_footer: route.query.feature_footer || '',
    }),
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: ThanksForWatchingView,
    meta: { transition: 'slide-in' },
    props: route => ({
      feature_footer: route.query.feature_footer || '',
    }),
  },
  {
    path: '/bg',
    name: 'backgroundView',
    component: BackgroundView,
    meta: { transition: 'slide-in' },
    props: route => ({
      feature_footer: route.query.feature_footer || '',
    }),
  },
  {
    path: '/brb',
    name: 'BRB',
    component: BeRightBack,
    meta: { transition: 'slide-in' },
    props: route => ({
      feature_footer: route.query.feature_footer || '',
    }),
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView,
    meta: { transition: 'slide-in' },
    props: route => ({
      feature_footer: route.query.feature_footer || '',
    }),
  },
  {
    path: '/scoreboard/overwatch/:matchID',
    name: 'OverWatch',
    component: OverWatch,
    meta: { transition: 'slide-in' },
    props: route => ({
      feature_footer: route.query.feature_footer || '',
    }),
  },
  {
    path: '/MatchUp/:matchID',
    name: 'MatchUpMatchUp',
    component: MatchUp,
    props: route => ({
      team_a_c: route.query.team_a_color || '',
      team_b_c: route.query.team_b_color || '',
      team_a_name: route.query.team_a_name || '',
      team_b_name: route.query.team_b_name || '',
      team_a_score: route.query.team_a_score || '',
      team_b_score: route.query.team_b_score || '',
      is_gradient : {
        default: true,
        type: Boolean
      },
      feature_footer: route.query.feature_footer || '',
    }),
    meta: { transition: 'slide-in' },
  },
  {
    path: '/MatchUp/default/1/:matchID',
    name: 'DefaultMatchup1',
    component: DefaultMatchup1,
    props: route => ({
      team_a_c: route.query.team_a_color || '',
      team_b_c: route.query.team_b_color || '',
      team_a_name: route.query.team_a_name || '',
      team_b_name: route.query.team_b_name || '',
      team_a_score: route.query.team_a_score || '',
      team_b_score: route.query.team_b_score || '',
      is_gradient : {
        default: true,
        type: Boolean
      },
      feature_footer: route.query.feature_footer || '',
    }),
    meta: { transition: 'slide-in' },
  },
  {
    path: '/MatchUp/default/2/:matchID',
    name: 'DefaultMatchup2',
    component: DefaultMatchup2,
    props: route => ({
      team_a_c: route.query.team_a_color || '',
      team_b_c: route.query.team_b_color || '',
      team_a_name: route.query.team_a_name || '',
      team_b_name: route.query.team_b_name || '',
      team_a_score: route.query.team_a_score || '',
      team_b_score: route.query.team_b_score || '',
      is_gradient : {
        default: true,
        type: Boolean
      },
      feature_footer: route.query.feature_footer || '',
    }),
    meta: { transition: 'slide-in' },
  },
  {
    path: '/groupstanding',
    name: 'groupstanding',
    component: GroupStanding,
    meta: { transition: 'slide-in' },
    props: route => ({
      feature_footer: route.query.feature_footer || '',
    }),
  },
  {
    path: '/scoreboard/default/:matchID',
    name: 'ScoreBoardDefault',
    component: ScoreBoardDefault,
    props: route => ({
      team_a_c: route.query.team_a_color || '',
      team_b_c: route.query.team_b_color || '',
      team_a_name: route.query.team_a_name || '',
      team_b_name: route.query.team_b_name || '',
      team_a_score: route.query.team_a_score || '',
      team_b_score: route.query.team_b_score || '',
      feature_footer: route.query.feature_footer || '',

    }),
    meta: { transition: 'slide-in' },
  },
    {
    path: '/scoreboard/rocketleague/:matchID',
    name: 'RocketLeague',
    component: RocketLeague,
    props: route => ({
      team_a_c: route.query.team_a_color || '',
      team_b_c: route.query.team_b_color || '',
      team_a_name: route.query.team_a_name || '',
      team_b_name: route.query.team_b_name || '',
      team_a_score: route.query.team_a_score || '',
      team_b_score: route.query.team_b_score || '',
      feature_footer: route.query.feature_footer || '',

    }),
    meta: { transition: 'slide-in' },
  },
  {
    path: '/tournamentMatches/:NoCells',
    name: 'tournamentMatches',
    component: tournamentMatches,
    meta: { transition: 'slide-in' },
    props: (route) => {
      const NoCells = Number.parseInt(route.params.NoCells, 10)
      if (Number.isNaN(NoCells)) {
        return 0
      }
      return { NoCells }
    }
  },
  {
    path: '/startingsoon/:matchID',
    name: 'StartingSoon',
    component: StartingSoon,
    meta: { transition: 'slide-in' },
  
  },
  {
    path: '/footer',
    name: 'AppFooter',
    component: AppFooter,
    meta: { transition: 'slide-in' },
    props: route => ({
      footer_background: route.query.footer_background || '',
      text_color: route.query.text_color || '',
    })
  
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
