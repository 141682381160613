
<template>
  <div class="w-full h-full flex justify-center items-center flex pt-4">
    <form @submit.prevent="submitLoginData" class="flex flex-col py-3 px-5 justify-center space-y-6 w-[50%] border border-white rounded-3xl">
      <h2 class="my-2 font-black">Login</h2>
      <input type="email" autocomplete="username" placeholder="Enter your Email" v-model="loginData.email" class="bg-white rounded-lg p-3 my-2" >
      <input type="password" autocomplete="current-password" placeholder="Enter Your Password" v-model="loginData.password" class="bg-white rounded-lg p-3 my-2" >
      <input type="submit" value="Login" class="bg-white text-3xl font-black rounded-lg p-2 my-2" >
    </form>

    <!-- Popup for login status -->
    <div v-if="loginStatus" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div class="bg-white p-4 rounded-lg">
        <p v-if="loginStatus === 'approved'" class="text-green-600">Sign in approved!</p>
        <p v-if="loginStatus === 'denied'" class="text-red-600">Sign in denied.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginData: {
        email: null,
        password: null,
      },
      loginStatus: null, // null, 'approved', 'denied'
    }
  },
  methods: {
    submitLoginData() {
      this.$axios.post("api/login", this.loginData).then((resp) => {
        this.$store.dispatch("isAuthenticated", true);
        this.$store.dispatch("token", resp.data.data.token);
        this.loginStatus = 'approved';
        setTimeout(() => this.$router.push('/'), 2000); // Redirect after 2 seconds
      }).catch((error) => {
        this.loginStatus = 'denied';
        console.error('Login failed:', error);
      });
    }
  }
}
</script>

<style scoped>
/* Add any additional styling you need here */
</style>