<template>
      <div v-if="matchJson" class="d-flex flex-row h-full pt-5 justify-around text-white">
        <div class="w-[450px] h-[70px] rounded bg-[#262626] flex justify-end items-center text-white">
            <p class="font-bold text-3xl px-2">{{ matchJson.data.matches.teamA.name }}</p>
            <img class="w-[65px] h-[65px] team-logo rounded" :src="matchJson.data.matches.team_a_logo" alt="">
            <div class="w-[110px] h-[70px] bg-[#808080] rounded flex justify-center items-center">
                <p class="text-white text-5xl font-black">
                {{this.team_a_score_count}}
                </p>
            </div>
        </div>
        <div class="w-[450px] h-[70px] rounded bg-[#262626] flex justify-start items-center text-white">
            <div class="w-[110px] h-[70px] bg-[#808080] rounded flex justify-center items-center">
                <p class="text-white text-5xl font-black">
                {{this.team_b_score_count}}
                </p>
            </div>
            <img class="w-[65px] h-[65px] team-logo rounded" :src="matchJson.data.matches.team_b_logo" alt="">
            <p class="font-bold text-3xl px-2">{{ matchJson.data.matches.teamB.name }}</p>
        </div>
    
    </div>
</template>

<script>
export default {
    data(){
        return{
            matchJson:null,
        }
    },
    mounted(){
        this.loadData()
    },
methods:{
    loadData: async function() {
        this.$axios.get("api/matches/" + this.$route.params.matchID).then((resp) => {
          this.matchJson = resp.data;
          this.team_a_score_count = resp.data.data.matches.score_a;
          this.team_b_score_count = resp.data.data.matches.score_b;
          this.team_a_score_count = 0;
          this.team_b_score_count = 0;
        });
      },
}
}
</script>

<style>

</style>