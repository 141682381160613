<template>
  <div class="text-white flex pt-4 px-5">
    <div class="grid" :class="{'grid-cols-2':baseURL}">
    <form class="!bg-black flex flex-col justify-center space-y-6  border border-white rounded-3xl p-2" @submit.prevent="generateURL">
      <h1>UGC Insight</h1>
      <h2>Match Configuration</h2>
      <div class="flex space-x-3 w-fit mx-auto items-center">

      <label class="mx-2" for="matchCode">Match Code:</label>
      <input class="mx-2" v-model="formData.match_code" type="text" id="matchCode" name="match_code" placeholder="Enter match code">
      <button class="bg-white mx-2 px-2 font-bold rounded py-1 border !border-pink-500 shadow shadow-white text-black" type="button" @click="loadMatch">Go &#8594;</button>

      <label for="matchUUID">Match UUID:</label>
      <input v-model="formData.matchUUID" type="text" id="matchUUID" name="matchUUID">
    </div>
      <!-- Team A Customization -->
      <div class="grid grid-cols-2 gap-3">
      <div class="grid grid-cols-2 items-center">
      <h2 class="col-span-2">Team A Customization</h2>
      <label for="teamAColor">Team A Color:</label>
      <input type="color" id="teamAColor" name="team_a_c" v-model="formData.team_a_c">

      <label for="teamANameColor">Team A Name Color:</label>
      <input type="color" id="teamANameColor" name="team_a_name" v-model="formData.team_a_name">

      <label for="teamAScoreColor">Team A Score Color:</label>
      <input type="color" id="teamAScoreColor" name="team_a_score" v-model="formData.team_a_score">
    </div>
    <div class="grid grid-cols-2 items-center gap-4">

      <!-- Team B Customization -->
      <h2 class="col-span-2">Team B Customization</h2>
      <label for="teamBColor">Team B Color:</label>
      <input type="color" id="teamBColor" name="team_b_c" v-model="formData.team_b_c">

      <label for="teamBNameColor">Team B Name Color:</label>
      <input type="color" id="teamBNameColor" name="team_b_name" v-model="formData.team_b_name">

      <label for="teamBScoreColor">Team B Score Color:</label>
      <input type="color" id="teamBScoreColor" name="team_b_score" v-model="formData.team_b_score">
    </div>
    <div class="grid grid-cols-2 items-center gap-4">
      <!-- Scene Settings -->
      <h2 class="col-span-2">Scene Settings</h2>
      <label for="scene">Select Scene:</label>
      <select id="scene" class="dropdown w-fit !text-black" v-model="formData.scene">
        <option selected value="matchup">Match Up Card</option>
        <option value="scoreboard">Scoreboard (default)</option>
        <option value="scoreboard-rl">Scoreboard (rocket league)</option>
        <option value="scoreboard-ow">Scoreboard (overwatch)</option>
      </select>

      <label for="greenScreen">Green Screen Color:</label>
      <input type="color" id="greenScreen" name="green_screen" v-model="formData.green_screen">

      <label for="greenScreen">Green Screen Transparent:</label>
      <input class="w-fit h-[23px] w-[23px]" type="checkbox" id="greenScreenTransparent" name="green_screen_transparent" v-model="formData.isGreenTransparent">

      <label for="isGradient">Use Gradient Background:</label>
      <input class="w-fit h-[23px] w-[23px]" type="checkbox" id="isGradient" name="is_gradient" v-model="formData.is_gradient">

      <label for="bottomTicker">Enable Bottom Feed Ticker:</label>
      <input class="w-fit h-[23px] w-[23px]" type="checkbox" id="bottomTicker" name="bottom_ticker" v-model="formData.bottom_ticker">
    </div>
    <div class="grid grid-cols-2 items-center gap-4">
      <!-- Screen Size Customization -->
      <h2 class="col-span-2">Screen Size Customization</h2>
      <label for="screenHeight">Screen Height (Pixels):</label>
      <input type="number" id="screenHeight" class="w-fit" name="screen_height" v-model="formData.screen_height" placeholder="e.g., 1080">

      <label for="screenWidth">Screen Width (Pixels):</label>
      <input type="number" id="screenWidth" class="w-fit" name="screen_width" v-model="formData.screen_width" placeholder="e.g., 1920">
    </div>
    </div>
      <button class="rounded p-2 bg-white text-black my-2 w-fit mx-auto font-bold" type="button" @click="generateURL">Generate Scene</button>
      <button class="rounded p-2 bg-white text-black my-2 w-fit mx-auto font-bold" type="button" @click="openGeneratedURL">Open Scene</button>

      <h2>Generated Scene:</h2>
      <p id="generatedURL" class="break-all px-3" v-if="baseURL">{{ baseURL }}</p>
      <button class="rounded p-2 bg-white text-black my-2 w-fit mx-auto font-bold" type="button" @click="copyURLToClipboard">Copy</button>

      <h2>Scene Preview:</h2>
      <button class="rounded p-2 bg-white text-black my-2 w-fit mx-auto font-bold" type="button" @click="saveScenePreview">Save Scene Preview</button>
    </form>
    <div class="flex justify-center items-center">
      <iframe v-if="baseURL"  id="previewIframe" class="m-auto" ref="previewIframeRef" :src="currentGeneratedURL" width="800" height="600"></iframe>

    </div>
  </div>
  </div>
</template>

<script>
  import html2canvas from 'html2canvas';
    export default {

    data() {
        return {
            currentGeneratedURL: '',
            baseURL: '',
            loadingMatchData: false,
            formData: {
                match_code: '',
                matchUUID: '',
                team_a_c: '#9e0000',
                team_a_name: '#FFFFFF',
                team_a_score: '#FFFFFF',
                team_b_c: '#FFFFFF',
                team_b_name: '#FFFFFF',
                team_b_score: '#FFFFFF',
                scene: null,
                green_screen: '#00b140',
                isGreenTransparent: false,
                is_gradient: false,
                bottom_ticker: false,
                screen_height: null,
                screen_width: null
            }
        };
    },

    created() {
      this.formData.scene = 'matchup'
        const urlParams = new URLSearchParams(window.location.search);
        console.log('Starting to process URL parameters...');  // Initial log statement

        for (const [key, value] of urlParams.entries()) {
            console.log(`Processing key: ${key}, value: ${value}`);  // Log each key-value pair

            if (Object.prototype.hasOwnProperty.call(this.formData, key)) {
                console.log(`Key '${key}' exists in formData. Updating value to: #${value}`);

                // Check for boolean as they are special cases
                if (value === 'true' || value === 'false') {
                  
                    this.formData[key] = value === 'true';
                    console.log(`Set boolean key: ${key} to value: ${this.formData[key]}`);  // Log the boolean assignment
                } else {
                  if((key=='matchUUID') || (key=='scene')){
                    this.formData[key] = decodeURIComponent(value);
                    console.log(`Set key: ${key} to decoded value: ${this.formData[key]}`);  // Log the decoded assignment
                    
                    }
                    else{
                    this.formData[key] = '#'+decodeURIComponent(value);
                    console.log(`Set key: ${key} to decoded value: ${this.formData[key]}`);  // Log the decoded assignment
                    
                    }
                  }
            }
        }
        console.log('Finished processing URL parameters.');  // Final log statement
    },
    methods: {

      setIframeZoom(){
        if(this.formData.screen_height>1000){
        const iframe = document.getElementById("previewIframe")
        iframe.contentWindow.document.body.className = "zoom-fit";
        iframe.width = this.formData.screen_width/2 || '960'
        iframe.height = this.formData.screen_height/2 || '540'
        }
      },
      generateURL() {
          // let workingBaseURL = this.baseURL ? this.baseURL : 'https://insight.ugc.gg'; // Default base URL
          let workingBaseURL = ''
          const formData = this.formData;
          const params = [];
          this.$store.dispatch("greenColor", formData['green_screen']);
          this.$store.dispatch("isGreenTransparent", formData['isGreenTransparent']);
          for (const key in formData) {
              if (Object.prototype.hasOwnProperty.call(formData, key) && formData[key] !== '' && formData[key] !== null) {
                if (typeof formData[key] === 'string' && formData[key].includes('#')) {
                  // Remove '#' from the value using String.replace()
                  params.push(`${key}=${encodeURIComponent(formData[key].replace('#', ''))}`);
                  // formData[key].replace('#', '');
                }
                else{
                  params.push(`${key}=${encodeURIComponent(formData[key])}`);
                }
              }
          }
          const queryString = params.join('&');
          const scene = formData.scene;
          switch (scene) {
              case 'matchup':
                  workingBaseURL += '/matchup';
                  break;
              case 'scoreboard':
                  workingBaseURL += '/scoreboard/default';
                  break;
              case 'scoreboard-rl':
                  workingBaseURL += '/scoreboard/rocketleague';
                  break;
              case 'scoreboard-ow':
                  workingBaseURL += '/scoreboard/overwatch';
                  break;
              default:
                  break;
          }

          // Check if the matchUUID matches the UUID format
          const matchUUID = this.formData.matchUUID ||  '2a43c556-7786-4c11-ab04-5a4e62bb95b1';
          const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
          if (uuidPattern.test(matchUUID)) {
              workingBaseURL += `/${matchUUID}`;
          } else {
              console.error('Invalid UUID format');
              return;  // Exit if invalid UUID
          }
          
          const finalURL = `${window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "")}${workingBaseURL}?${queryString}`;
          this.baseURL = finalURL
          this.currentGeneratedURL = `${workingBaseURL}?${queryString}`;
          setTimeout(() => {
          this.setIframeZoom()
          },4000)
      },
      fetchMatchData() {
      this.loadingMatchData = true; // Set loading state to true
      // Your fetchMatchData logic here...

      // Simulating an async operation with setTimeout
      setTimeout(() => {
        this.loadingMatchData = false; // Set loading state back to false
        alert('Fetching match data...');
      }, 2000); // Replace 2000 with the actual time your fetch operation takes
    },
      loadMatch() {
        this.$axios.get('/api/matches/overlay/' + this.formData.match_code)
        .then((resp) => {
          this.formData.matchUUID =  resp.data.data.players.playersA[0].match_uuid
        })
        .catch((e) => {
          window.alert('Invalid Code' + e)
        })
      },
      openGeneratedURL() {
        if (this.currentGeneratedURL) {
      this.$store.dispatch("showModal", false);
          document.body.style.height = `${this.formData.screen_height}px`; 
          document.body.style.width = `${this.formData.screen_width}px`;
          this.$router.push(this.currentGeneratedURL);
        } else {
          alert('No URL to open.');
        }
      },
      copyURLToClipboard() {
        let baseURL =  window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") || 'https://insight.ugc.gg';

        const generatedURL = baseURL+  this.currentGeneratedURL;
        if (generatedURL) {
          const textarea = document.createElement('textarea');
          textarea.textContent = generatedURL;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
          alert('URL copied to clipboard!');
        } else {
          alert('No URL to copy.');
        }
      },
      async saveScenePreview() {
  const iframe = document.getElementById('previewIframe');
  const iframeContent = iframe.contentDocument || iframe.contentWindow.document;

  // Check if the iframe content is fully loaded
  if (iframeContent.readyState === 'complete') {
    const iframeBody = iframeContent.body;

    // Use html2canvas to capture the screenshot
    html2canvas(iframeBody).then((canvas) => {
      // Convert the canvas to a data URL (PNG image)
      const imgURL = canvas.toDataURL('image/png');

      // Create a download link and trigger a click to download the screenshot
      const link = document.createElement('a');
      link.href = imgURL;
      link.download = 'scene_preview.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  } else {
    alert('Please wait for the preview to load');
  }
}

    },
  };
  </script>
  <style>
input[type=text],[type=number], select{
  border-radius: 5px;
  padding: 5px !important;
  color:black;
  
}
.zoom-fit{
  zoom: 0.5;
}

</style>
  
