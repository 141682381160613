<template>
<div class="home">
  <BeRightBackScene message="We'll be right back!" time="5 Minutes" />
</div>
</template>

<script>
// @ is an alias to /src
import BeRightBackScene from '@/components/scenes/BeRightBack.vue'

export default {
  name: 'BeRightBack',
  components: {
    BeRightBackScene,
  },
  mounted(){
    const gradientString = this.$route.query.feature_footer;
    const GradientBool = gradientString === 'true' ? true : false;
    this.$store.dispatch("feature_footer", GradientBool);
  }
}
</script>
