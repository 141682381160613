<template>
    <div class="relative">
        <video id="background-video" class="w-full h-full" autoplay muted loop>
        <source src="../assets/welcom-back.webm" type="video/mp4">
        <!-- Add additional source elements for different video formats if needed -->
        Your browser does not support the video tag.
    </video>
    <div v-if="tournamentData" class="back">
        <div class="flex flex-col space-y-3 w-full h-full justify-center items-center">
        <img class="w-[200px] h-[100px]" style="filter: invert(1);" src="../assets/logo-black.png" alt="Logo"> <!-- Add the logo element here -->
        <p class="text-white text-[100px] font-bold">Welcome</p>
        <p class="text-[30px] text-white">{{getHeadlineText}}</p>
        <p class="text-white text-[60px] font-bold">{{tournamentData.tournament.name}}</p>
        <p class="text-white text-[50px] font-bold">{{tournamentData.tournament.game.shortname}} | {{tournamentData.tournament.game.gamemode[0].name}}</p>

    </div>
    </div>

    </div>
</template>
<script>
import store from "../store";
import axios from '../boot/axios'
const customAxiosInstance = axios.create({
  baseURL: 'https://www.ugcesports.gg/api',
  headers: {
            'Authorization': `Bearer ${store.getters.token}`
        }
});
export default{
    computed:{
        getHeadlineText(){
            if(this.$route.query.HeadlineText){
                return this.$route.query.HeadlineText
            }
            else{
                return 'welcome To UGC Esports'
            }
        }
    },
    data(){
        return {
            tournamentData:null
        }
    },
    methods:{
        getTournamentData(){
            customAxiosInstance.get(`/tournaments/${this.$route.params.tournamentID}`).then((resp) => {
           this.tournamentData = resp.data.data

        });
    }
},
mounted(){
    this.getTournamentData()
}
}
</script>
<style>
video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0; /* Place the video behind other elements */
}
.back{
    background-color: rgba(0, 0, 97, 0.534);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1; /* Place the video behind other elements */
}
</style>