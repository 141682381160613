<template>
  <div class="h-full">
    <div class="d-flex flex-column h-full justify-content-center align-items-center">
      <div class="!relative">
        <div class="logo-landing">
          <img :style="!mode?'filter: invert(0)':'filter: invert(1)'" class="" src="../assets/logo.png" alt="">
        </div>
      </div>
      <br>
      <input v-model="code" maxlength='5' type="text" class="codeinput" placeholder="Enter Code Here">
      <p class="mt-6"></p>
      <button @click="toggleSettings" class="settings-btn">
        <img id="settings" src="../assets/settings.png" alt="Settings">
      </button>
      <button @click="codeSubmit" class="btnsub mt-3">GET STARTED</button>
    </div>
    <div class="p-8"><div class="settings" v-show="showSettings">
      <p>👇 Select Your Scene 👇</p>
          <select v-model="scene" class="dropdown">
            <option value="matchup">Match Up Card</option>
            <option value="scoreboard">Scoreboard (default)</option>
            <option value="scoreboard-rl">Scoreboard (rocket league)</option>
            <option value="scoreboard-ow">Scoreboard (overwatch)</option>
          </select>
          <button @click="codeSubmit" class="btnsub mt-3">GET STARTED</button>
        <br>
          <!-- <h3>Scene Color Changes</h3>
          <h3>Team A</h3>
          <div id="team_color">
            <label>Background Color:</label>
          <input v-model="team_a_c" type="color">
          </div>
          <h3>Team B</h3>
          <div id="team_color">
          <label>Team B Color:</label>
          <input v-model="team_b_c" type="color">
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
export default {
  name: 'ThanksForWatching',
  components: {
  },
  data() {
    return {
      code: 13337,
      scene: 'matchup', // default value
      showSettings: false,
      team_a_c: null,
      team_b_c: null,
      team_color_start: '#000000',
    }
  },
  computed: {
    ...mapGetters(["mode"]),
  },
  mounted() {
    const gradientString = this.$route.query.feature_footer;
    const GradientBool = gradientString === 'true' ? true : false;
    this.$store.dispatch("feature_footer", GradientBool);
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    // Remove event listener before component unmounts
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    toggleSettings() {
      this.showSettings = !this.showSettings;
    },
    handleKeyDown(event) {
      // Check for Ctrl + S key combination
      if (event.ctrlKey && event.key === 's' ) {
        this.toggleSettings();
      }
    },
    codeSubmit: function () {
      if (this.code != null) {

        switch (this.scene) {
          case 'matchup':
            this.navigateTo('/matchup')
            break
          case 'scoreboard':
            this.navigateTo('/scoreboard/default')
            break
            case 'scoreboard-rl':
            this.navigateTo('/scoreboard/rocketleague')
            break
            case 'scoreboard-ow':
            this.navigateTo('/scoreboard/overwatch')
            break
          default:
            break
        }

      }
    },
    navigateTo(routePath) {
      this.$axios.get('/api/matches/overlay/' + this.code)
        .then((resp) => {
          if (!this.team_a_c) {
            this.$store.dispatch('team_a_color', resp.data.data.matches.team_a_logo_color);
          } else {
            this.$store.dispatch('team_a_color', this.team_a_c);
          }

          if (!this.team_b_c) {
            this.$store.dispatch('team_b_color', resp.data.data.matches.team_b_logo_color);
          } else {
            this.$store.dispatch('team_b_color', this.team_b_c);
          }

          this.$router.push({ path: routePath + '/' + resp.data.data.players.playersA[0].match_uuid })
        })
        .catch((e) => {
          window.alert('Invalid Code' + e)
        })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo-landing{
  position: absolute;
  right: -52px; /* Adjust the left position to align the logo as desired */
  top: 50%;
  transform: translateY(-100%);
  height: 33px; /* Adjust the height of the logo as desired */
  fill: #000; /* Set the fill color to black */
  z-index: 100;
  padding: 33px;
  animation: logo-translate 2s alternate;
  width: 100px;
}
.p {
  font-size: 14px;
}

.codeinput {
  background: #1c1c1c;
  color: white;
  padding: 9px !important;
  border-radius: 12px;
  width: 132px;
  font-weight: 300;
  text-align: center;
}

.codeinput::placeholder {
  color: white;
}

.btnsub {
  background: #41AC4E;
  color: white;
  border-radius: 12px;
  width: 132px;
  font-weight: 900;
}

h1 {
  font-size: 99px;
  color: white;
}

h2 {
  font-size: 66px;
}

p {
  font-size: 33px;
  color: grey;
}
.settings {
  background-color: white;
  padding: 9px;
}

/* Styling for the settings button */
.settings-btn {
  position: absolute;
  top: 33px;
  right: 33px;
  background-color: transparent;
  border: none;
  filter: grayscale(100%);
  cursor: pointer;
}
.team_color {
  margin: 33px;
}

.scene-selection {
  margin-top: 33px;
  margin-bottom: 33px;
}

/* Hide the dropdown by default */
[d]iv>.dropdown {
  display: none;
}

/* Show the dropdown when the settings icon is clicked */
[d]iv>.dropdown:focus,
[d]iv>.dropdown:focus+button:focus {
  display: block;
}</style>
