<template>
  <div id="app"
  class="bg-transparent"
  >
    <router-view/>
    <!-- <img @click="changeMode(true)" v-if="!mode" class="mode-img" src="./assets/light-mode.png" alt="">
    <img @click="changeMode(false)" v-if="mode" class="mode-img" src="./assets/night-mode.png" alt=""> -->

    <modal-preferene v-if="showModal" class="absolute inset-0 flex flex-col z-[550] justify-center items-center"/>
    <transition name="footer-slide">
      <div v-if="feature_footer" class="footer">
        <img class="logo bg-white" src="./assets/logo-black.png" alt="Logo"> <!-- Add the logo element here -->
        <div class="footer-text">{{ footerText }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalPreferene from "./views/ModalPreference.vue";
export default {
  components:{ModalPreferene},
  computed: {
    ...mapGetters(["mode"]),
    ...mapGetters(["greenColor"]),
    ...mapGetters(["feature_footer"]),
    ...mapGetters(["showModal"]),
    getFeatureActivity(){
      return this.$router.query.feature_footer
    }
  },
  data: function() {
    return {
      match_id: "",
      overlay_code: "",
      isModal:false,
      footerText: "👋 Hey viewers, thanks for tuning in! 📺 Don't forget to LIKE, SHARE, and SUBSCRIBE! 🙏 Follow us on Instagram @YourSchoolIG for daily updates 📸 | Join our Facebook community at YourSchoolFB for important announcements 📣 | Check out our Twitter @YourSchoolTweet for real-time news 🐦 | Use #YourSchoolRocks for a chance to be featured 🌟 | For exclusive content, find us on Snapchat: YourSchoolSnap 👻 | Also on LinkedIn: YourSchoolLinkedIn for networking opportunities 👥 | Save the date for our upcoming events 🗓️ | Did you know you can sponsor our program? Visit our Playday page to learn more. We appreciate your support! 💙",
      feature_activity: true, // Add a boolean data property to control the footer
    };
  },
  created(){
    this.$store.dispatch("showModal", false);
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },

  methods: {
    handleKeyDown(event) {
      // Check for Ctrl + S key combination
      if (event.shiftKey && event.key === 'S' ) {
      this.isModal = !this.isModal
      this.$store.dispatch("showModal", this.isModal);

      }
    },
    changeMode(a) {
      this.$store.dispatch("mode", a);
    },
    changeModalVisibility(){
      this.$store.dispatch("showModal", this.isModal);
    }
  },
};
</script>

<style>
@font-face {
  font-family: "EuroStile";
  src: url("./assets/eurostile.ttf");
}
@font-face {
  font-family: "EuroStileBold";
  src: url("./assets/EurostileBold.ttf");
}
.font-euro-bold{
  font-family: EuroStileBold !important;
}

#app {
  font-family: Avenir, EuroStile, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  letter-spacing: 2px;
  min-width: 1920px;
  min-height: 1080px;
}

.logo {
  position: absolute;
  left: 33px; /* Adjust the left position to align the logo as desired */
  top: 50%;
  transform: translateY(-50%);
  height: 33px; /* Adjust the height of the logo as desired */
  fill: #000; /* Set the fill color to black */
  z-index: 100;
  padding: 33px;
  animation: logo-translate 2s;
}

@font-face {
  font-family: "EuroStile";
  src: url("./assets/eurostile.ttf");
}

* {
  margin: 0 !important;
  padding: 0 !important;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

.h-full {
  min-height: 1080px;
  height: 1080px;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.mode-img {
  position: absolute;
  bottom: 75px;
  right: 60px;
  width: 100px;
}

.min-height {
  min-height: 1080px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 66px;
  width: 100%;
  background-color: #fff;
  font-size: 33px;
  color: #000;
  padding: 66px;
  text-align: center;
}

.footer-text {
  animation: footer-slide 33s linear infinite;
}

@keyframes footer-slide {
  0% {
    transform: translateX(95%);
  }
  100% {
    transform: translateX(-95%);
  }
}
@keyframes logo-translate {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
html,body, #app {
background-color: transparent !important;
}
</style>
