<template>
    <div class="w-100 h-full backgroundImage" >
      <div v-if="matchJson" style="filter: brightness(1.5);" class="fade-in-image grid grid-cols-10 w-100 h-full text-white">
        <div class="team-div col-span-4 flex flex-col justify-center items-center " :style="{ background: this.gradientBackgroundA }">
          <img class="w-300 mx-auto moving-logo" :src="matchJson.data.matches.team_a_logo" alt="">
          <p class="team-name" :style="'color:'+this.getTeamAName">{{matchJson.data.matches.teamA.name}}</p>
          <p class="seed" :style="'color:'+this.getTeamAScore"> <b class="text-green-500">{{ matchJson.team_b_seed }} </b> {{matchJson.data.matches.score_a}}</p>
        </div>
    
        <!-- <p class="score " :class="mode?'color-dark':'color-light'"><b>{{matchJson.data.matches.score_a}}</b></p> -->
        <div class="col-span-2 flex flex-col justify-center items-center space-y-3 vs-div">
          <div class="flex flex-col justify-center items-center">
            <p :class="mode?'color-dark':'color-light'" class="text-[175px] h-[190px]">VS</p>
            <p class="uppercase">SemiFinals</p>
          </div>
          <div class="relative logo !top-0">
            <img :style="!mode?'filter: invert(0)':'filter: invert(1)'" class="logo !left-0" src="../assets/logo.png" alt="">
          </div>
    
        </div>
        <!-- <p class="score" :class="mode?'color-dark':'color-light'"><b>{{matchJson.data.matches.score_b}}</b></p> -->
        <div class="team-div col-span-4 flex flex-col justify-center items-center" :style="{ background: this.gradientBackgroundB }">
          <img id="logo" class="w-300 mx-auto moving-logo" :src="matchJson.data.matches.team_b_logo" alt="">
          <p class="team-name" :style="'color:'+this.getTeamBName" >{{matchJson.data.matches.teamB.name}}</p>
          <p class="seed" :style="'color:'+this.getTeamBScore">  <b class="text-green-500">{{ matchJson.team_b_seed }} </b> {{matchJson.data.matches.score_b}}</p>
        </div>
      </div>
      <div v-else class="d-flex align-items-center justify-content-around w-100 h-full">
        <img src="../assets/loading.svg" alt="">
      </div>
    </div>
    </template>
    
    <script>
    import { mapGetters } from "vuex";
    export default {
        name: 'DefaultMatchup1',
      data() {
        return {
          matchJson: null,
          feature_gradients: true,
          feature_color_added: false,
        }
      },
      computed: {
        ...mapGetters(["mode"]),
        ...mapGetters(["team_a_color"]),
        ...mapGetters(["team_b_color"]),
        getTeamAColor(){
          if(this.$route.query.team_a_c !=undefined){
            return '#'+this.$route.query.team_a_c
          }
          else{
            return this.team_a_color
          }
        },
        getTeamBColor(){
          if(this.$route.query.team_b_c !=undefined){
            return '#'+this.$route.query.team_b_c
          }
          else{
            return this.team_b_color
          }
        },
        getTeamAName(){
          if(this.$route.query.team_a_name !=undefined){
            return '#'+this.$route.query.team_a_name
          }
          else{
            return this.mode===true? '#000':'#fff'
          }
        },
        getTeamBName(){
          if(this.$route.query.team_b_name !=undefined){
            return '#'+this.$route.query.team_b_name
          }
          else{
            return this.mode===true? '#000':'#fff'
          }
        },
        getTeamAScore(){
          if(this.$route.query.team_a_score !=undefined){
            return '#'+this.$route.query.team_a_score
          }
          else{
            return this.mode===true? '#000':'#fff'
          }
        },
        getTeamBScore(){
          if(this.$route.query.team_b_score !=undefined){
            return '#'+this.$route.query.team_b_score
          }
          else{
            return this.mode===true? '#000':'#fff'
          }
        },
        gradientBackgroundA() {
          const gradientString = this.$route.query.is_gradient;
          const GradientBool = gradientString === 'true' ? true : false;
          if(GradientBool){
            if(this.$route.query.team_a_c !=undefined){
              return `linear-gradient(to right, ${this.getTeamAColor}, black)`;
            }
            else{
              return `linear-gradient(to right, ${this.team_a_color}, black)`;
            }
          }
          else 
          return this.getTeamAColor
        },
        gradientBackgroundB() {
          const gradientString = this.$route.query.is_gradient;
          const GradientBool = gradientString === 'true' ? true : false;
          if(GradientBool){
            if(this.$route.query.team_b_c !=undefined){
              return `linear-gradient(to left, ${this.getTeamBColor}, black)`;
            } else{
              return `linear-gradient(to left, ${this.team_b_color}, black)`;
            }
          }
          else 
          return this.getTeamBColor;
        }
      },
      mounted() {
        const gradientString = this.$route.query.feature_footer;
        const GradientBool = gradientString === 'true' ? true : false;
        this.$store.dispatch("feature_footer", GradientBool);
        this.loadData();
      },
      watch: {
        '$route'() {
          this.loadData()
        }
      },
      methods: {
        loadData: function() {
          this.$axios.get("api/matches/" + this.$route.params.matchID, this.authorization).then((resp) => {
            this.matchJson = resp.data;
          });
        },
        toggleSettings() {
          this.showSettings = !this.showSettings;
        },
        handleKeyDown(event) {
          // Check for Ctrl + S key combination
          if (event.ctrlKey && event.key === 's' ) {
            this.toggleSettings();
          }
         },
    }
    }
    </script>
    
    <style>
    .card {
      margin: 200px;
      border-radius: 66px;
    }
    .team-div{
      width: 100%;
      padding: 100px 0 !important;
      opacity: .85;
    }
    .backgroundImage{
        background-image: url('../assets/defaultBackground.png');
    }
    
    .h-full {
      min-height: 1080px;
      height: 1080px;
    }
    
    .score {
      font-size: 198px;
      color: white;
      font-weight: bold;
    }
    
    .vs-div {
      color: white;
      font-size: 45px;
      font-weight: 500 !important;
      background: #4e4e4e;
      border-left: #7c7c7c 16px solid;
      border-right: #7c7c7c 16px solid;
      /* Apply the drop shadow to both sides with negative horizontal and vertical offsets */
    }
    
    .seed {
      font-size: 66px;
      color: grey;
      font-weight: bold;
    }
    
    
    .w-300 {
      width: 300px;
      height: 300px;
      border-radius: 9px;
    }
    
    .vs-text {
      font-size: 99px;
      font-weight: 700;
      color: white;
    }
    
    .team-name {
      font-size: 60px;
      font-weight: bold;
      color: white;
    }
    
    .fade-in-image {
      animation: fadeIn 6s;
    }
    
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
    
      100% {
        opacity: 1;
      }
    }
    
    /* loading animation */
    @keyframes ldio-dg3jvjy6zxr {
      0% {
        transform: rotate(0deg)
      }
    
      50% {
        transform: rotate(180deg)
      }
    
      100% {
        transform: rotate(360deg)
      }
    }
    
    .ldio-dg3jvjy6zxr div {
      position: absolute;
      animation: ldio-dg3jvjy6zxr 0.65s linear infinite;
      width: 160px;
      height: 160px;
      top: 20px;
      left: 20px;
      border-radius: 50%;
      box-shadow: 0 4px 0 0 #ffffff;
      transform-origin: 80px 82px;
    }
    
    .loadingio-spinner-eclipse-6nu50wkqk6 {
      width: 200px;
      height: 200px;
      display: inline-block;
      overflow: hidden;
      background: #ffffff;
    }
    
    .ldio-dg3jvjy6zxr {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0;
      /* see note above */
    }
    
    .ldio-dg3jvjy6zxr div {
      box-sizing: content-box;
    }
    </style>
    