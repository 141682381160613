<template>
<div id="app">
</div>
</template>

<script>
export default{
  mounted(){
    const gradientString = this.$route.query.feature_footer;
const GradientBool = gradientString === 'true' ? true : false;
this.$store.dispatch("feature_footer", GradientBool);
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scene {
  height: auto;
  width: auto;
  padding: 33px;
  background-color: green;
}

h1 {
  font-size: 99px;
  color: white;
}

h2 {
  font-size: 66px;
}

p {
  font-size: 33px;
  color: grey;
}
</style>
