<template>
    <div class="d-flex justify-content-center w-100 row my-5 py-5">
      <div v-if="standings" class="w-60 py-2 bg-gray border-bottom border-gray d-flex">
        <img @click="previous" class="mx-2 ml-4 rotateimg180" src="../assets/right-arrow.svg" alt="">
        <img @click="next" class="mx-2" src="../assets/right-arrow.svg" alt="">
      </div>
      <div v-if="standings" class="w-60 bg-gray">
          <div  class="bg-gray">
              <div  v-for="(items,index) in current" :key="index" class="matchRow row mx-0 border-bottom border-gray text-uppercase">
                  <div class="col-md-2 text-center py-2">
                      <div>
                        <h3 class="font-weight-bold">{{(items.startdate.substring(11, 13)%12)}}:{{items.startdate.substring(14, 16)}}:{{items.startdate.substring(17, 19)}}</h3>
                        <p class="font-weight-bold">{{(items.startdate.substring(11, 13))>= 12 ? 'pm' : 'am'}}/PT</p>
                      </div>
                  </div>
                  <div class="col-md-2 bg-red">
                      <img :src="items.team_a_logo" alt="">
                  </div>
                  <div class="col-md-6 py-2">
                        <p class="font-weight-bold" v-if="items.score_a">{{items.score_a}} : {{items.score_b}}</p>
                      <p v-else class="mb-1">Round {{items.round}}</p>
                      <h6>{{items.team_a_name}} VS {{items.team_b_name}}</h6>
                  </div>
                  <div class="col-md-2 bg-red">
                    <img :src="items.team_b_logo" alt="">
                  </div>
              </div>
          </div>
      </div>
      <div v-else class="d-flex flex-column align-items-center justify-content-around w-100 h-full">
                <img src="../assets/loading.svg" alt="">
                <h3 class="text-light">Loading</h3>
            </div>
            
    </div>
  </template>
  
  <script>
  export default {
  data(){
      return {
        standings: null,
        current:null,
        currentIndex:0,
        counter:0
      }
  },
  mounted(){
  this.getStandings()
  const gradientString = this.$route.query.feature_footer;
const GradientBool = gradientString === 'true' ? true : false;
this.$store.dispatch("feature_footer", GradientBool);
  },
  props:{
    NoCells:{
      type: Number,
      default:6,
    }
  },
  methods:{
          getStandings: function() {
        this.$axios.get("api/tournaments/219/matches").then((resp) => {
          this.standings = resp.data;
          this.current = this.standings.data.matches.slice(0,this.NoCells)
        });
      },
      previous(){
        if(this.currentIndex!=0){
          this.currentIndex = this.currentIndex-this.NoCells
        } else{
          this.currentIndex = 0
        }
        this.current = this.standings.data.matches.slice(this.currentIndex,(this.currentIndex+this.NoCells))
      },
      next(){
        if((this.currentIndex+this.NoCells)<this.standings.data.matches.length){
          this.currentIndex = this.currentIndex+this.NoCells
        }
        else{
          this.current = this.currentIndex
        }
        this.current = this.standings.data.matches.slice(this.currentIndex,(this.currentIndex+this.NoCells))
      },
  }
  }
  </script>
  
  <style>
  .bg-gray{
      background: rgb(212, 212, 212);
  }
  .row{
      margin:0;
  }
  .matchRow div{
    padding: 14px 0;
  }
  .border-gray{
      border-block-color: gray;
  }
  .bg-red{
    background: red;
  }
  .w-60{
    width: 60%;
  }
.matchRow img{
    width: 100%;
    height: 100px;
  }
  .rotateimg180 {
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
  </style>