import { render, staticRenderFns } from "./BeRightBack.vue?vue&type=template&id=600bb1d2&scoped=true"
import script from "./BeRightBack.vue?vue&type=script&lang=js"
export * from "./BeRightBack.vue?vue&type=script&lang=js"
import style0 from "./BeRightBack.vue?vue&type=style&index=0&id=600bb1d2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "600bb1d2",
  null
  
)

export default component.exports