import store from "../store";
import axios from 'axios';
 
const axiosInstance = axios.create({
  baseURL: "https://www.ugcesports.gg"
});

// Add a request interceptor to update the Authorization header
axiosInstance.interceptors.request.use(
  function(config) {
    const token = store.getters.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
