<template>
<div class="home">
    <div class="flex">
        <p>Transmission complete</p>
        <h1>Thanks for watching</h1>
        <p>Project HUD</p>
      </div>
</div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'ThanksForWatching',
  mounted(){
    const gradientString = this.$route.query.feature_footer;
    const GradientBool = gradientString === 'true' ? true : false;
    this.$store.dispatch("feature_footer", GradientBool);
  }
}
</script>
<style scoped>
.particles-js-canvas-el{
  position: absolute;
  top: 0;
  background: black;
}
.flex{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%
}
.scene {
  height: auto;
  width: auto;
  padding: 33px;
  background-color: green;
  color: white;
  height: 1080px;
  width: 1920px;
}

h1 {
  font-size: 99px;
  color: white;
}

h2 {
  font-size: 66px;
}

p {
  font-size: 33px;
  color: grey;
}
</style>
