import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import './index.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import axios from './boot/axios'
Vue.prototype.$axios = axios;

Vue.use(axios)
Vue.config.productionTip = false

import './assets/index.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Define a route guard
router.beforeEach((to, from, next) => {
  
  if(to.name !== 'LoginView' && !store.getters.isAuthenticated && !store.getters.token) {
    // If the user is not authenticated, redirect them to the login page
    next('/login')
  } else {
    // If the user is authenticated or the route doesn't require authentication, proceed to the route
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
