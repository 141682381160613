<template>
  <div class="d-flex flex-column h-100 justify-content-end h-full align-items-center">
    <div v-if="matchJson" class="position-relative score-div">
    <div  class="scoreboard start-50">
    <div  class="d-flex bg-light text-dark">
        <div class="py-4 bg-dark px-2 d-flex justify-centent-center">
      <img
      v-for="(image, index) in images"
      :key="index"
      :class="{
      'opacity-0': currentIndex !== index,
      'hidden': currentIndex !== index
      }"
      class="logo-score transition-opacity duration-1000"
      :src="image"
      alt=""
      />
        </div>
        <div :style="'background-color:'+ getTeamAColor +'!important'" class="d-flex flex-column align-items-center justify-content-between match-team-div">
            <div class="d-flex justify-content-between align-items-center px-2 py-2 w-100">
                <div class="d-flex align-items-center">
                  <!-- :src="matchJson.data.matches.team_a_logo" -->
                    <img class="w-[90px] h-[90px] team-logo" :src="matchJson.data.matches.team_a_logo" alt="">
                    <p class="team-name ml-1 text-uppercase" :style="'color:'+ getTeamAName">{{matchJson.data.matches.teamA.name.slice(0,this.team_a_name_length)}}</p>
                </div>
                <p class="team-name team-score text-center" :style="'color:'+ getTeamAScore">{{this.team_a_score_count}}</p>
            </div>
            <!-- <div class="d-flex justify-content-between align-items-center w-100 px-2 pr-5">
              <div v-if="matchJson.data.matches.score_a>matchJson.data.matches.score_b" class="d-flex">
                    <span  v-for="items in matchJson.data.matches.score_a-matchJson.data.matches.score_b" :key="items" class="span-white"></span>
                </div>
                <p>{{this.matchJson.data.matches.team_b_logo_color}}</p>
            </div> -->
        </div>
        <div :style="'background-color:'+ getTeamBColor +'!important'" class="d-flex flex-column align-items-center justify-content-between match-team-div team-b-div">
            <div class="d-flex justify-content-between align-items-center px-2 py-2 w-100">
                <div class="d-flex align-items-center">
                  <!-- :src="matchJson.data.matches.team_b_logo" -->
                    <img class="w-[90px] h-[90px] team-logo" :src="matchJson.data.matches.team_b_logo" alt="">
                    <!-- {{matchJson.data.matches.team_b_name.slice(0,3)}} -->
                    <p class="team-name ml-1 text-uppercase"  :style="'color:'+ getTeamBName">{{matchJson.data.matches.teamB.name.slice(0,this.team_b_name_length)}}</p>
                </div>
                <!-- {{matchJson.data.matches.score_b}} -->
                <p class="team-name text-center team-score"  :style="'color:'+ getTeamBScore">{{this.team_b_score_count}}</p>
            </div>
            <!-- <div class="d-flex justify-content-between align-items-center w-100 px-2 pr-5">
                <div v-if="matchJson.data.matches.score_b>matchJson.data.matches.score_a" class="d-flex">
                    <span  v-for="items in matchJson.data.matches.score_b-matchJson.data.matches.score_a" :key="items" class="span-white"></span>
                </div>
                <p>6-3</p>
            </div> -->
        </div>
    </div>
    </div>
  </div>
    <div v-else class="d-flex align-items-center justify-content-around w-100 h-full">
    <img src="../assets/loading.svg" alt="">
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import image1 from "../assets/logo.png"
import image2 from "../assets/logo.svg"
export default {
    data() {
    return {
      matchJson: null,
      images: [image1,image2],
      currentIndex: 0,
      color_team_a:'#000',
      team_a_score_count: 3,
      team_b_score_count: 3,
      team_a_name_length: 6,
      team_b_name_length: 6, 
      ctrlPressed: false,
      aPressed: false,
      bPressed: false,
      negativePressed: false,
      plusPressed: false,
      showFirstImage: true,
    }
  },
  computed: {
    ...mapGetters(["team_a_color"]),
    ...mapGetters(["team_b_color"]),
    getTeamAColor(){
      if(this.$route.query.team_a_c !=undefined){
        return '#'+this.$route.query.team_a_c
      }
      else{
        return this.team_a_color
      }
    },
    getTeamBColor(){
      if(this.$route.query.team_b_c !=undefined){
        return '#'+this.$route.query.team_b_c
      }
      else{
        return this.team_b_color
      }
    },
    getTeamAName(){
      if(this.$route.query.team_a_name !=undefined){
        return '#'+this.$route.query.team_a_name
      }
      else{
        return '#000'
      }
    },
    getTeamBName(){
      if(this.$route.query.team_b_name !=undefined){
        return '#'+this.$route.query.team_b_name
      }
      else{
        return  '#000'
      }
    },
    getTeamAScore(){
      if(this.$route.query.team_a_score !=undefined){
        return '#'+this.$route.query.team_a_score
      }
      else{
        return '#000'
      }
    },
    getTeamBScore(){
      if(this.$route.query.team_b_score !=undefined){
        return '#'+this.$route.query.team_b_score
      }
      else{
        return  '#000'
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
    this.loadData();
    const gradientString = this.$route.query.feature_footer;
    const GradientBool = gradientString === 'true' ? true : false;
    this.$store.dispatch("feature_footer", GradientBool);
    setInterval(() => {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
    // totalViews++;
    // this.team_a_score_count = totalViews;
    }, 15000);
  },
  beforeUnmount() {
    // Remove event listener before component unmounts
    window.removeEventListener('keydown', this.handleKeyDown);
  },
 

  watch: {
    '$route'() {
      this.loadData()
    }
  },
  methods: {
     loadData: async function() {
      this.$axios.get("api/matches/" + this.$route.params.matchID).then((resp) => {
        this.matchJson = resp.data;
        this.team_a_score_count = resp.data.data.matches.score_a;
        this.team_b_score_count = resp.data.data.matches.score_b;
        this.team_a_score_count = 0;
        this.team_b_score_count = 0;
      });
    },
    resetKeys(){
      this.ctrlPressed = false;
      this.aPressed = false;
      this.bPressed = false;
      this.plusPressed = false;
      this.negativePressed = false;
    },

    handleKeyDown(event) {
      // Check for Ctrl + S key combination
      if (event.key === 'Control') {
        this.ctrlPressed = true;
      } else if (event.key === 'a') {
        this.aPressed = true;
      } else if (event.key === 'b') {
        this.bPressed = true;
      } else if (event.key === '+') {
        this.plusPressed = true;
      } else if (event.key === '-') {
        this.negativePressed = true;
      }

      //Team A + 1
      if (this.ctrlPressed && this.aPressed && this.plusPressed) {
        this.team_a_score_count++;
        this.resetKeys();
        console.log("Ctrl + A and + pressed simultaneously");
      }
          
      if (this.ctrlPressed && this.aPressed && this.negativePressed) {
        this.team_a_score_count--;
        this.resetKeys();
        console.log("Ctrl + A and Ctrl - pressed simultaneously");
      }

      //Team B + 1
      if (this.ctrlPressed && this.bPressed && this.plusPressed) {
        this.team_b_score_count++;
        this.resetKeys();
        console.log("Ctrl + B and + pressed simultaneously");
      }

      if (this.ctrlPressed && this.bPressed && this.negativePressed) {
        this.team_b_score_count--;
        this.resetKeys();
        console.log("Ctrl + B and - pressed simultaneously");
      }
  
   
    },
  }
}
</script>

<style scoped>



.opacity-0 {
  opacity: 0;
}
.team-name{
    font-weight: bolder;
    font-size: 33px;
}
.score-div{
  width: 750px;
  height: 300px;

}
.logo-score{
    width: 90px;
    border-radius: 12px;
    animation-name: fade-in;
  animation-duration: 1s;
}
.team-score{
  animation: fadeIn 5s;
  font-weight: 900;
  font-size: 66px;
  text-shadow: black 10px;
}
.scoreboard{
  position: absolute;
  bottom: 99px;
  animation-name: bottomtop;
  animation-duration: 2s;
}
.match-team-div{
    width: 320px;
    /* background: rgb(0,55,130);
    background: linear-gradient(0deg, rgba(0,55,130,0.8715861344537815) 0%, rgba(71,0,223,1) 64%); */
    color: white !important;
}
.span-white{
    background: #7e7e7e;
    border-radius: 999px;
    height: 3px;
    width: 25px;
    margin: 0px 2px !important;
}
.team-logo {
  border-radius: 9px;
  box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.33);
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bottomtop {
  from {bottom: 0;}
  to {bottom: 99px;}
}
</style>