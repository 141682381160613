<template>
    <div class="d-flex flex-column h-full justify-content-start">
      <RocketLeagueScene></RocketLeagueScene>

    </div>
    
  </template>

  <script>
  import { mapGetters } from "vuex";
  import RocketLeagueScene from "@/components/scenes/RocketLeagueScene.vue";
  export default {
    components:{
    RocketLeagueScene,
},
      data() {
      return {
        matchJson: null,
        color_team_a:'#000',
        team_a_score_count: 3,
        team_b_score_count: 3,
        team_a_name_length: 6,
        team_b_name_length: 6,
        ctrlPressed: false,
        aPressed: false,
        bPressed: false,
        negativePressed: false,
        plusPressed: false,
      }
    },
    computed: {
      ...mapGetters(["team_a_color"]),
      ...mapGetters(["team_b_color"]),
      getTeamAColor(){
        if(this.$route.query.team_a_c !=undefined){
          return '#'+this.$route.query.team_a_c
        }
        else{
          return this.team_a_color
        }
      },
      getTeamBColor(){
        if(this.$route.query.team_b_c !=undefined){
          return '#'+this.$route.query.team_b_c
        }
        else{
          return this.team_b_color
        }
      },
      getTeamAName(){
        if(this.$route.query.team_a_name !=undefined){
          return '#'+this.$route.query.team_a_name
        }
        else{
          return this.mode===true? '#000':'#fff'
        }
      },
      getTeamBName(){
        if(this.$route.query.team_b_name !=undefined){
          return '#'+this.$route.query.team_b_name
        }
        else{
          return this.mode===true? '#000':'#fff'
        }
      },
      getTeamAScore(){
        if(this.$route.query.team_a_score !=undefined){
          return '#'+this.$route.query.team_a_score
        }
        else{
          return this.mode===true? '#000':'#fff'
        }
      },
      getTeamBScore(){
        if(this.$route.query.team_b_score !=undefined){
          return '#'+this.$route.query.team_b_score
        }
        else{
          return this.mode===true? '#000':'#fff'
        }
      },
    },
    mounted() {
      window.addEventListener('keydown', this.handleKeyDown);
      const gradientString = this.$route.query.feature_footer;
      const GradientBool = gradientString === 'true' ? true : false;
      this.$store.dispatch("feature_footer", GradientBool);
    },
    beforeUnmount() {
      // Remove event listener before component unmounts
      window.removeEventListener('keydown', this.handleKeyDown);
    },
    methods: {
      resetKeys(){
        this.ctrlPressed = false;
        this.aPressed = false;
        this.bPressed = false;
        this.plusPressed = false;
        this.negativePressed = false;
      },

      handleKeyDown(event) {
        // Check for Ctrl + S key combination
        if (event.key === 'Control') {
          this.ctrlPressed = true;
        } else if (event.key === 'a') {
          this.aPressed = true;
        } else if (event.key === 'b') {
          this.bPressed = true;
        } else if (event.key === '+') {
          this.plusPressed = true;
        } else if (event.key === '-') {
          this.negativePressed = true;
        }

        //Team A + 1
        if (this.ctrlPressed && this.aPressed && this.plusPressed) {
          this.team_a_score_count++;
          this.resetKeys();
          console.log("Ctrl + A and + pressed simultaneously");
        }

        if (this.ctrlPressed && this.aPressed && this.negativePressed) {
          this.team_a_score_count--;
          this.resetKeys();
          console.log("Ctrl + A and Ctrl - pressed simultaneously");
        }

        //Team B + 1
        if (this.ctrlPressed && this.bPressed && this.plusPressed) {
          this.team_b_score_count++;
          this.resetKeys();
          console.log("Ctrl + B and + pressed simultaneously");
        }

        if (this.ctrlPressed && this.bPressed && this.negativePressed) {
          this.team_b_score_count--;
          this.resetKeys();
          console.log("Ctrl + B and - pressed simultaneously");
        }


      },
    }
  }
  </script>

  <style scoped>
  .bg-score{
    background-image: url('../assets/scoreboard.png');
    background-repeat: no-repeat;
  }
  </style>