<template>
    <div  v-if="matchJson" class="flex justify-center mt-5 w-full">
    <div class="d-flex text-white flex-column justify-content-end align-items-center bg-score  w-[59%]">
        <div class="flex justify-around w-full !py-[2rem] font-bold font-euro-bold">
            <h3 class="uppercase text-[15px] w-[310px] transform -translate-x-[22px]">{{matchJson.data.matches.teamA.name}}</h3>
            <h3 class="uppercase"></h3>
            <h3 class="uppercase text-[15px] w-[310px] transform translate-x-[22px]">{{matchJson.data.matches.teamB.name}}</h3>
        </div>
        <div class="flex justify-between py-1 space-x-5 text-white py-[2rem] !mt-[1.5rem] font-black uppercase w-[41%]">
            <h1>{{team_a_score_count}}</h1>
            <div class="flex flex-col items-center">
                <p>Best of five</p>
                <h3>Quaterfinals</h3>
            </div>
            <h1>{{team_b_score_count}}</h1>
        </div>
    </div>
</div>
</template>
<script>
export default{
    name:'RocketLeagueScene',
    data(){
        return{
            matchJson:null,
            team_a_score_count:0,
            team_b_score_count:0,
        }
    },
    methods: {
       loadData: async function() {
        this.$axios.get("api/matches/" + this.$route.params.matchID).then((resp) => {
          this.matchJson = resp.data;
          this.team_a_score_count = resp.data.data.matches.score_a;
          this.team_b_score_count = resp.data.data.matches.score_b;
          this.team_a_score_count = 0;
          this.team_b_score_count = 0;
          console.log(resp.data.data.matches)
        });
      },
    },
    mounted(){
        this.loadData();
    },
    watch: {
      '$route'() {
        this.loadData()
      },
    },

    
}
</script>
<style>
  .bg-score{
    background-image: url('@/assets/scoreboard.png');
    background-repeat: no-repeat;
  }
</style>