<template>
  <div class="d-flex flex-column mt-5 justify-items-center">
    <p>Stay Tuned</p>
    <h1>{{ message }}</h1>
    <h2>
      <Countdown end="11/11/2022 00:05:00" />
    </h2>
    <p>Project HUD</p>
  </div>
</template>

<script>
import Countdown from 'vuejs-countdown'
export default {
  name: 'BeRightBackScene',
  props: {
    message: String,
    time: String,
  },
  components: {
    Countdown,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-size: 99px;
  color: white;
}

h2 {
  font-size: 66px;
}

p {
  font-size: 33px;
  color: grey;
}
</style>
