<template>
    <div>
        <transition name="footer-slide">
      <div class="footer transparent-bg" :style="`background: ${footerBackground}` ">
        <img class="logo" src="../assets/logo-black.png" alt="Logo"> <!-- Add the logo element here -->
        <div class="footer-text " :style="`color: ${textColor}; white-space: nowrap;`">👋 Hey viewers, thanks for tuning in! 📺 Don't forget to LIKE, SHARE, and SUBSCRIBE! 🙏 Follow us on Instagram @YourSchoolIG for daily updates 📸 | Join our Facebook community at YourSchoolFB for important announcements 📣 | Check out our Twitter @YourSchoolTweet for real-time news 🐦 | Use #YourSchoolRocks for a chance to be featured 🌟 | For exclusive content, find us on Snapchat: YourSchoolSnap 👻 | Also on LinkedIn: YourSchoolLinkedIn for networking opportunities 👥 | Save the date for our upcoming events 🗓️ | Did you know you can sponsor our program? Visit our Playday page to learn more. We appreciate your support! 💙</div>
      </div>
    </transition>
    </div>
</template> 
<script>
export default{
  computed:{
    footerBackground(){
        if(this.$route.query.footer_background !=undefined){
          return '#'+this.$route.query.footer_background
        }
        return null
      },
      textColor(){
        if(this.$route.query.text_color !=undefined){
          return '#'+this.$route.query.text_color
        }
        return null
      },
  }
}
</script>
<style>
.transparent-bg{
  background-color: transparent;
}
</style>