import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  mode: false,
  greenColor: '#000',
  isGreenTransparent: false,
  team_a_color: '#000',
  team_b_color: '#000',
  feature_footer: false,
  showModal: false,
  token: null,
  isAuthenticated: false,
};

const mutations = {
  isGreenTransparent(state, isGreenTransparent) {
    state.isGreenTransparent = isGreenTransparent;
  },
  token(state, token) {
    state.token = token;
  },
  isAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  greenColor(state, greenColor) {
    state.greenColor = greenColor;
  },
  mode(state, mode) {
    state.mode = mode;
  },
  showModal(state, showModal) {
    state.showModal = showModal;
  },
  team_a_color(state, team_a_color) {
    state.team_a_color = team_a_color;
  },
  team_b_color(state, team_b_color) {
    state.team_b_color = team_b_color  },
    feature_footer(state, feature_footer) {
    state.feature_footer = feature_footer  },
};

const actions = {
  isGreenTransparent(context, isGreenTransparent) {
    context.commit("isGreenTransparent", isGreenTransparent);
  },
  token(context, token) {
    context.commit("token", token);
  },   
  isAuthenticated(context, isAuthenticated) {
    context.commit("isAuthenticated", isAuthenticated);
  },
  mode(context, mode) {
    context.commit("mode", mode);
  },
  greenColor(context, greenColor) {
    context.commit("greenColor", greenColor);
  },
  showModal(context, showModal) {
    context.commit("showModal", showModal);
  },
  team_a_color(context, team_a_color) {
    context.commit("team_a_color", team_a_color);
  },
  team_b_color(context, team_b_color) {
    context.commit("team_b_color", team_b_color);
  },
  feature_footer(context, feature_footer) {
    context.commit("feature_footer", feature_footer);
  },

};
const getters = {
  isGreenTransparent: (state) => {
    return state.isGreenTransparent;
  },
  mode: (state) => {
    return state.mode;
  },
  token: (state) => {
    return state.token;
  },
  isAuthenticated: (state) => {
    return state.isAuthenticated;
  },
  greenColor: (state) => {
    return state.greenColor;
  },
  showModal: (state) => {
    return state.showModal;
  },
  team_b_color: (state) => {
    return state.team_b_color;
  },
  team_a_color: (state) => {
    return state.team_a_color;
  },
  feature_footer: (state) => {
    return state.feature_footer;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};