<template>
  <div class="w-100 row mt-5">
    <div class="col-md-6 group-div px-5">
        <div class="bg-gray fw-bold">
            <h2 class="py-2 border border-bottom-3 border-gray mb-0">Group A
            </h2>
            <div class="row mx-1 border-top border-bottom border-gray py-2">
                <div class="col-md-2">
                    lskadjfalskd
                </div>
                <div class="col-md-7">
                    asdfasdf
                </div>
                <div class="col-md-2 bg-gray">
                    asdfasdfsad
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 group-div px-5">
        <div class="bg-gray fw-bold">
            <h2 class="py-2 border border-bottom-3 border-gray mb-0">Group B
            </h2>
            <div class="row  border-top border-bottom border-gray py-2">
                <div class="col-md-2">
                    lskadjfalskd
                </div>
                <div class="col-md-7">
                    asdfasdf
                </div>
                <div class="col-md-2 bg-gray">
                    asdfasdfsad
                </div>
            </div>
        </div>
    </div>
    <div v-if="standings" class="text-light">{{standings.data.matches[0]}}</div>
  </div>
</template>

<script>

export default {
data(){
    return {
      standings: null,
    }
},
mounted(){
const gradientString = this.$route.query.feature_footer;
const GradientBool = gradientString === 'true' ? true : false;
this.$store.dispatch("feature_footer", GradientBool);
this.getStandings()
},
methods:{
        getStandings: function() {
      this.$axios.get("api/tournaments/219/matches").then((resp) => {
        this.standings = resp.data;
      });
    },
}
}
</script>

<style>
.bg-gray{
    background: rgb(212, 212, 212);
}
.row{
    margin:0;
}
.border-gray{
    border-block-color: gray;
}
</style>