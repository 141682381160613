<template>
    <div class="w-100 h-full">
        <div v-if="matchJson"
             :style="{ backgroundImage: 'url(https://www.pixelstalk.net/wp-content/uploads/images1/Backgrounds-Rocket-League-HD.jpg)' }"
             class="fade-in-image grid grid-cols-1 w-100 h-full text-white">
            <!-- Team Display (Team A or Team B based on the 'team' query parameter) -->
            <div class="team-div flex flex-col justify-center items-center"
                 :style="{ background: gradientBackgroundA }">
                <div class="logo-and-name"
                     @mouseover="animateLogoAndName"
                     @mouseout="stopAnimation">
                    <img class="w-300 mx-auto moving-logo"
                         :src="matchJson.data.matches.team_a_logo"
                         alt="" />
                    <p class="team-name" :style="'color:' + getTeamAColor">
                        {{
            matchJson.data.matches.teamA.name
                        }}
                    </p>
                </div>
                <p class="seed" :style="'color:' + getTeamAScore">
                    <b>
                        {{
          matchJson.data.matches.score_a
                        }}
                    </b>
                </p>
                <!-- Stream Starting Soon text -->
                <p class="stream-starting-soon">Stream Starting Soon</p>
                <!-- Sponsors (Replace this with your actual sponsors content) -->
                <div class="sponsors">
                    <div class="sponsor">
                        <img src="https://playday.ugcesports.gg/images/cornerstone/eco.png" alt="Sponsor 1" />
                        <p>Sponsor 1</p>
                    </div>
                    <div class="sponsor">
                        <img src="https://playday.ugcesports.gg/images/cornerstone/eco.png" alt="Sponsor 2" />
                        <p>Sponsor 2</p>
                    </div>
                </div>
            </div>

        </div>
        <div v-else class="d-flex align-items-center justify-content-around w-100 h-full">
            <img src="../assets/loading.svg" alt="" />
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                matchJson: null,
                feature_gradients: true,
                feature_color_added: false,
                isAnimating: false,
            };
        },
        computed: {
            ...mapGetters(["mode"]),
            ...mapGetters(["team_a_color"]),
            ...mapGetters(["team_b_color"]),
            getTeamAColor() {
                if (this.$route.query.team_a_c != undefined) {
                    return "#" + this.$route.query.team_a_c;
                } else {
                    return this.team_a_color;
                }
            },
            getTeamAScore() {
                if (this.$route.query.team_a_score != undefined) {
                    return "#" + this.$route.query.team_a_score;
                } else {
                    return this.mode === true ? "#000" : "#fff";
                }
            },
            gradientBackgroundA() {
                const gradientString = this.$route.query.is_gradient;
                const GradientBool = gradientString === "true" ? true : false;
                if (GradientBool) {
                    if (this.$route.query.team_a_c != undefined) {
                        return `linear-gradient(to right, ${this.getTeamAColor}, black)`;
                    } else {
                        return `linear-gradient(to right, ${this.team_a_color}, black)`;
                    }
                } else return this.getTeamAColor;
            },
        },
        mounted() {
            const gradientString = this.$route.query.feature_footer;
            const GradientBool = gradientString === "true" ? true : false;
            this.$store.dispatch("feature_footer", GradientBool);
            this.loadData();
        },
        watch: {
            $route() {
                this.loadData();
            },
        },
        methods: {
            loadData: function () {
                this.$axios
                    .get("api/matches/" + this.$route.params.matchID, this.authorization)
                    .then((resp) => {
                        this.matchJson = resp.data;
                    });
            }, 
            animateLogoAndName() {
                this.isAnimating = true;
                // Add your animation logic here to make the logo and name animate.
                // Example: this.$refs.logoName.style.transform = "translateY(-10px)";
            },
            stopAnimation() {
                this.isAnimating = false;
                // Stop the animation here.
                // Example: this.$refs.logoName.style.transform = "translateY(0)";
            },
        },
    };
</script>

<style>
    /* Your styles here */

    /* Add styles for sponsors */
    .sponsors {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-width: 100%;
        gap: 20px;
    }

    .sponsor {
        text-align: center;
        margin: 10px;
        transition: transform 0.3s ease-in-out;
    }

        /* Increase the size of sponsor logos */
        .sponsor img {
            max-width: 150px; /* Adjust image size as needed */
        }

    .logo-and-name {
        transition: transform 0.3s ease-in-out;
    }

    /* Remove animation from stream-starting-soon */
    .stream-starting-soon {
        font-size: 40px;
        margin-top: 20px;
    }
</style>
